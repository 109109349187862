import { render, staticRenderFns } from "./WelcomeMessage.vue?vue&type=template&id=f800e7c2&scoped=true&"
import script from "./WelcomeMessage.vue?vue&type=script&lang=ts&"
export * from "./WelcomeMessage.vue?vue&type=script&lang=ts&"
import style0 from "./WelcomeMessage.vue?vue&type=style&index=0&id=f800e7c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f800e7c2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-mono/sam-mono/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f800e7c2')) {
      api.createRecord('f800e7c2', component.options)
    } else {
      api.reload('f800e7c2', component.options)
    }
    module.hot.accept("./WelcomeMessage.vue?vue&type=template&id=f800e7c2&scoped=true&", function () {
      api.rerender('f800e7c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/WelcomeOnboard/WelcomeMessage/WelcomeMessage.vue"
export default component.exports
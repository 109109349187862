var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "welcome-message-container d-flex justify-center align-center",
    },
    [
      _c("div", { staticClass: "welcome-message" }, [
        _c(
          "div",
          { staticClass: "welcome-message-content" },
          [
            _c(
              "p",
              {
                staticClass:
                  "font-21 medium-font text-center color-primary welcome-to",
              },
              [_vm._v("WELCOME TO")]
            ),
            _c("v-img", {
              attrs: {
                contain: "",
                width: "61%",
                src: require("../../../assets/images/search-ads-maven.svg"),
              },
            }),
            _c(
              "p",
              {
                staticClass:
                  "font-16 medium-font text-center color-primary px-10 my-8",
              },
              [
                _vm._v(
                  "Let's begin by setting up your integrations. You will need your Apple ID and if your ASA utilizes dual factor authentication, you will need the associated device."
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-center mt-10" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/welcome-onboard/setup" } },
                  [
                    _c(
                      "v-btn",
                      { staticClass: "mt-10", attrs: { color: "primary" } },
                      [_c("strong", [_vm._v("BEGIN SETUP ")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
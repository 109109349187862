




















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
    name: 'WelcomeMessage',
    data() {
        return {
        };
    },
    props: {

    },
    methods: {

    },
});
